import { StateModel, StrictState } from "@autocorp/redux";
import { frameHandler } from "@host";
import { getCampaignOutput } from "@util/campaign";
import { mfq } from "@host";
import type { LeadCampaignInputType, GetWidgetDetailsQuery } from "@graphql/schema";

export interface IDealerState {
    companyId?: string;
    /** @deprecated use `companyId` */
    widgetId: string;
    ctaId?: string;
    variant?: string;
    widget?: GetWidgetDetailsQuery["getWidgetDetails"],
    sourceUrl: string;
    primaryColor: string;
    secondaryColor: string;
    buttonBg: string;
    campaign?: LeadCampaignInputType;
}

const defaultDealerState: StrictState<IDealerState> = {
    companyId: IS_PRODUCTION ? frameHandler.company : (
        frameHandler.company || COMPANY_ID || ""
    ),
    widgetId: IS_PRODUCTION ? frameHandler.widgetId : (
        frameHandler.widgetId || WIDGET_ID || ""
    ),
    ctaId: "",
    variant: "",
    widget: undefined,
    sourceUrl: frameHandler.sourceUrl,
    primaryColor: "#16B47A",
    secondaryColor: "#1BCA8A",
    buttonBg: "#16B47A",
    campaign: getCampaignOutput(frameHandler.campaign),
};

export const DealerModel = new StateModel({
    name: "dealer",
    state: defaultDealerState,
    skipReset: true,
    updaters: {
        companyId: (newVal, state) => {
            if (newVal !== state.companyId) mfq.set("company", newVal);
            return {};
        },
        primaryColor: (newVal, state) => {
            if (newVal !== state.primaryColor) {
                const root = document.documentElement;
                root.style.setProperty("--primary-color", newVal);
            }
            return { ...state, primaryColor: newVal };
        },
        secondaryColor: (newVal, state) => {
            if (newVal !== state.secondaryColor) {
                const root = document.documentElement;
                root.style.setProperty("--secondary-color", newVal);
            }
            return { ...state, secondaryColor: newVal };
        },
        buttonBg: (newVal, state) => {
            if (newVal !== state.buttonBg) {
                const root = document.documentElement;
                root.style.setProperty("--button-bg", newVal);
            }
            return { ...state, buttonBg: newVal };
        },
    },
});

// useEffect(() => {
//     const root = document.documentElement;
//     root.style.setProperty("--primary-color", "#17A3F1");
//     root.style.setProperty("--secondary-color", "#1377C2");
// }, []);
