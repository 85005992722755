import {
    ApiClient,
    IApiClientArgs,
    QueryDefs,
    QueryType,
    ClientQueries,
} from "@autocorp/ava/esm/api";
import {
    testLeads,
    hostUrl,
    referrerUrl,
    errorHandler,
    AVA_TEST_LEAD_HEADER,
    ALS_REFERRER_HEADER,
} from "@host";
import {
    GetWidgetDetailsQuery,
    GetWidgetDetailsQueryVariables,
    SendNotificationMutation,
    SendNotificationMutationVariables,
    RequestVerificationMutation,
    RequestVerificationMutationVariables,
    ConfirmVerificationCodeMutation,
    ConfirmVerificationCodeMutationVariables,
    UpdateLeadMetaMutation,
    UpdateLeadMetaMutationVariables,
} from "@graphql/schema";

import { GetWidgetDetails } from "./query/widget";
import {
    SendNotification,
    RequestTwilioVerification,
    ConfirmTwilioVerification,
    UpdateLeadMeta,
} from "./query/lead";

export { gql } from "graphql-request";
export type { QueryDefs, QueryType };

const CUSTOM_HEADERS = [
    [AVA_TEST_LEAD_HEADER, testLeads.toString()],
    [ALS_REFERRER_HEADER, hostUrl?.href ?? referrerUrl.href],
];

type RootQueries = QueryDefs<{
    getWidgetDetails: QueryType<
        GetWidgetDetailsQuery,
        GetWidgetDetailsQueryVariables
    >;
    sendNotification: QueryType<
        SendNotificationMutation,
        SendNotificationMutationVariables
    >;
    requestVerification: QueryType<
        RequestVerificationMutation,
        RequestVerificationMutationVariables
    >;
    confirmVerificationCode: QueryType<
        ConfirmVerificationCodeMutation,
        ConfirmVerificationCodeMutationVariables
    >;
    updateLeadMeta: QueryType<
        UpdateLeadMetaMutation,
        UpdateLeadMetaMutationVariables
    >;
}>;

export const rootApi = new ApiClient<RootQueries>({
    apiUrl: API_URL,
    headers: (current) => {
        return new Promise((resolve) => {
            CUSTOM_HEADERS.forEach(([headerName, value]) => {
                if (!headerName || value === undefined) return;
                if (!current.has(headerName)) {
                    current.append(headerName, value);
                }
            });
            resolve();
        });
    },
    production: IS_PRODUCTION,
    dedupeLeads: !IS_PRODUCTION && DEDUPE,
    mockProfiles: TEST ? 0 : MOCK ? 1 : undefined,
    critical: {
        sendNotification: false,
    },
    errorHandler,
}, {
    getWidgetDetails: GetWidgetDetails,
    sendNotification: SendNotification,
    requestVerification: RequestTwilioVerification,
    confirmVerificationCode: ConfirmTwilioVerification,
    updateLeadMeta: UpdateLeadMeta,
});

export const mockProfile = rootApi.mockProfile;
export const rootApiClient = rootApi.getClient();

export const createNewClient = <
    TQueryDefs extends QueryDefs = QueryDefs,
>(
    queryDocs: ClientQueries<TQueryDefs>,
    options?: Partial<IApiClientArgs<TQueryDefs>>,
) => {
    return rootApi.extendClient<TQueryDefs, ClientQueries<TQueryDefs>>(
        queryDocs,
        options,
    );
};